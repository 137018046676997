import React from "react"
import "../wdyr"
import Help from "../components/help"

const HelpPage = () => {
  return (
    <>
      <Help />
   </>
  )
}

export default HelpPage
