import React, { useState, useEffect } from "react"
import SEO from "./seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import useForm from "../utils/useForm"
import validate from "../utils/validation/helpValidation"
import { Translations } from "../utils/translations"
import FormItem from "atomic-core/components/patterns/02-molecules/forms/FormItem"
import SuccessMessage from "./shared/auth/SuccessMessage"
import AuthErrorMessage from "./shared/auth"

const Help = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [gReCaptchaResponse, setGReCaptchaResponse] = useState("");

  const renderCaptcha = () => {
    window.onCaptchaStateChange = () => {
      const captchaResponse = window.grecaptcha.getResponse();
      setGReCaptchaResponse(captchaResponse);
    };
    window.recaptchaExpired = () => {
      window.grecaptcha.reset();
      setGReCaptchaResponse("");
      values.gReCaptcha = "";
    };
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    renderCaptcha();
  }, []);

  useEffect(() => {
    values.gReCaptcha = gReCaptchaResponse;
  }, [gReCaptchaResponse]);

  const handleHelp = async (
    { email, firstName, lastName, company, terms, helptext },
    resetForm
  ) => {
    try {
      if (!(typeof window !== `undefined`)) return false

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          name: firstName,
          lastName,
          company,
          terms,
          helptext,
          gReCaptchaResponse
        }),
      }

      let response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/help`,
        requestOptions
      )

      const contentType = response.headers.get("content-type")
      if (
        response.status !== 200 &&
        contentType &&
        contentType.indexOf("application/json") === -1
      ) {
        return setError(await response.text())
      }

      let data = await response.json()

      if (data && data.status === 200) {
        resetForm({})
        setSuccess(data.result)
      } else {
        setError(data.error)
      }
    } catch (e) {
      setError(e.message || Translations.ERROR.SOMETHING_WENT_WRONG)
    }
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleHelp,
    validate,
    setError,
    setSuccess
  )
  return (
    <>
      <SEO title="Help" />
      <div className="page help">
        <h1 className="text-size-h3 font-primary-bold mb-35">
          Help centre
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div className="background-color-monochrome-1 pt-40 pl-40 pb-40 pr-40 pl-xs-20 pt-xs-20 pb-xs-20 pr-xs-20 color-secondary-2 mb-20">
              {!success ? (
                <div className="faq-wrapper mb-20">
                  <form onSubmit={handleSubmit} method={"post"}>
                    <FormItem globalModifiers={"mb-20"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${
                            errors.firstName ? "form-control--error" : ""
                            }`}
                          name="firstName"
                          type="text"
                          maxLength="20"
                          value={values.firstName || ""}
                          onChange={handleChange}
                          placeholder={"First name"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.firstName}
                      </div>
                    </FormItem>

                    <FormItem globalModifiers={"mb-20"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${
                            errors.lastName ? "form-control--error" : ""
                            }`}
                          name="lastName"
                          maxLength="20"
                          type="text"
                          value={values.lastName || ""}
                          onChange={handleChange}
                          placeholder={"Last name"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.lastName}
                      </div>
                    </FormItem>

                    <FormItem globalModifiers={"mb-20"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${
                            errors.company ? "form-control--error" : ""
                            }`}
                          name="company"
                          type="text"
                          maxLength="50"
                          value={values.company || ""}
                          onChange={handleChange}
                          placeholder={"Company name"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.company}
                      </div>
                    </FormItem>

                    <FormItem globalModifiers={"mb-40"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${
                            errors.email ? "form-control--error" : ""
                            }`}
                          name="email"
                          type="email"
                          value={values.email || ""}
                          onChange={handleChange}
                          placeholder={"Email address"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.email}
                      </div>
                    </FormItem>
                    
                    <FormItem globalModifiers={"mb-40"}>
                      <div className={"form-item__control textarea"}>
                        <textarea
                          className={`form-control ${
                            errors.helptext ? "form-control--error" : ""
                            }`}
                          name="helptext"
                          type="textarea"
                          maxLength="2000"
                          value={values.helptext || ""}
                          onChange={handleChange}
                          placeholder={"How can I help you?"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.helptext}
                      </div>
                    </FormItem>

                    <FormItem globalModifiers={"mb-40"}>
                      <div className={"form-item__control textfield"}>
                        <div className={"d-xs-flex"}>
                          <div
                            className="g-recaptcha"
                            data-sitekey={process.env.GOOGLE_RECAPTCHA_SITEKEY}
                            data-callback="onCaptchaStateChange"
                            data-expired-callback="recaptchaExpired"
                          ></div>
                        </div>
                      </div>
                      {!gReCaptchaResponse && (
                        <div className={"form-item__error-message"}>
                          {errors.gReCaptcha}
                        </div>
                      )}
                    </FormItem>

                    <FormItem globalModifiers={"mb-40"}>
                      <div className="form-item__control checkbox">
                        <input
                          type="checkbox"
                          name="terms"
                          onChange={handleChange}
                          className="form-control"
                          id="share-contacts"
                        />
                        <label
                          className="form-item__label"
                          htmlFor="share-contacts"
                          aria-label="I agree for my contact details to be used in order to be contacted regarding my query"
                        >
                          <span
                            className="icon icon-size-16 mt-1"
                            aria-hidden="true"
                          >
                            <FontAwesomeIcon
                              className={"svg"}
                              icon={faCheck}
                              color={"rgb(176, 32, 114)"}
                            />
                          </span>
                          <span
                            className="form-item__label-text text-size-medium font-secondary-regular"
                            aria-hidden="true"
                          >
                            I agree for my contact details to be used in order
                            to be contacted regarding my query
                          </span>
                        </label>
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.terms}
                      </div>
                    </FormItem>

                    {error ? <AuthErrorMessage text={error} linkText="" afterText="" /> : ""}

                    <input
                      type="submit"
                      className={"button button--primary"}
                      value={"Send"}
                    />
                  </form>
                </div>
              ) : (
                  <SuccessMessage
                    heading={Translations.SUCCESS.HELP_HEADING}
                    text={Translations.SUCCESS.HELP_TEXT}
                  />
                )}
            </div>
          </div>
          <div className="col-md-6">
            {Translations.helpPage.faqSectionQuestionsAnswers.length > 0 && (
              <div className="background-color-monochrome-1 pt-40 pl-40 pb-40 pr-40 pl-xs-20 pt-xs-20 pb-xs-20 pr-xs-20 color-secondary-2 mb-20">
                <h5 className="text-size-h5 font-secondary-bold mb-20 color-secondary-2">
                  {Translations.helpPage.faqSectionTitle}
                </h5>
                {Translations.helpPage.faqSectionQuestionsAnswers.map((values, index) => {
                  return (
                    <div className="faq-wrapper mb-20" key={index}>
                      <div className="text-size-h5 font-secondary-bold color-secondary-2 mb-5">
                        {values.question}
                      </div>
                      <div className="text-size-h5 font-secondary-regular color-secondary-2">
                        {values.answer}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Help
