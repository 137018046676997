import { Translations } from "../translations"
import { isValidEmail, isValidString, isInputHtml } from "."

export default function validate(values) {
  let errors = {}

  if (!values.email) {
    errors.email = Translations.ERROR.EMAIL_REQUIRED
  } else if (!isValidEmail(values.email)) {
    errors.email = Translations.ERROR.INVALID_EMAIL
  }

  if (!values.firstName) {
    errors.firstName = Translations.ERROR.FIRSTNAME_REQUIRED
  } else if (!isValidString(values.firstName)) {
    errors.firstName = Translations.ERROR.ALPHABET_ONLY
  }

  if (!values.helptext) {
    errors.helptext = Translations.ERROR.HELPTEXT_REQUIRED
  } else if (
    isInputHtml(values.helptext)
  ) {
    errors.helptext = Translations.ERROR.HTML_JS_CODE_NOT_ALLOWE
  }

  if (!values.lastName) {
    errors.lastName = Translations.ERROR.LASTNAME_REQUIRED
  } else if (!isValidString(values.lastName)) {
    errors.lastName = Translations.ERROR.ALPHABET_ONLY
  }

  if (!values.company) {
    errors.company = Translations.ERROR.COMPANY_NAME_REQUIRED
  } else if (
    isInputHtml(values.company)
  ) {
    errors.company = Translations.ERROR.HTML_JS_CODE_NOT_ALLOWE
  }

  if (!values.gReCaptcha) {
    errors.gReCaptcha = Translations.ERROR.CAPTCHA_REQUIRED;
  }

  if (values.terms !== "true") {
    errors.terms = Translations.ERROR.CHECKBOX_SELECTION_REQUIRED_HELPPAGE
  }

  return errors
}
